import {
  createEffect,
  createEvent,
  createStore,
  sample,
  type Effect,
  type EventCallable,
  type Store,
  type StoreWritable,
} from 'effector'
import {
  AnalyticsEpgCategoryInfoOVP,
  AnalyticsEpgChannelInfoOVP,
  AnalyticsEpgProgramInfoOVP,
  AnalyticsEpgViewInfoOVP,
  AnalyticsEventTypeOVP as AnalyticsEventTypeEnum,
  AnalyticsGenericInfoOVP,
  AnalyticsPlayerOpenInfoOVP,
  AnalyticsSearchInfoOVP,
  ContentType,
  IContentInfoOVP,
  IEpgCategoryInfoOVP,
  IEpgChannelInfoOVP,
  IEpgInfoOVP,
  IEpgProgramInfoOVP,
  ISearchInfoOVP,
  VideoSourceInfoOVP,
  type ApplicationInfoOVP,
  type BEAnalyticsInfoOVP,
  type IURLData,
  type IUTMParams,
} from '../interfaces/analytics_ovp'
import { OVPUserInfo } from './auth/session'
import { OVPSessionData } from './auth/session/index.h'
import { base } from './content'
import { base as liveChannelBase } from './live'
import { base as searchBase } from './search'

export const $applicationInfo: StoreWritable<ApplicationInfoOVP | null> =
  createStore<ApplicationInfoOVP | null>(null)

export const $userInfo: StoreWritable<OVPUserInfo | null> =
  createStore<OVPUserInfo | null>(null)

export const $sessionInfo: StoreWritable<OVPSessionData | null> =
  createStore<OVPSessionData | null>(null)

export const $windowSessionGuid = createStore<string>('')

export const $analyticsDomain = createStore<string | null>(null)

export const $enabledAnalytics: Store<boolean> = $analyticsDomain.map(
  (domain) => Boolean(domain)
)

const getAnalyticsAppData = (
  app: ApplicationInfoOVP | null
): ApplicationInfoOVP => ({
  appVersion: app?.appVersion || '',
  appName: app?.appName || '',
  appId: app?.appId || '',
  browserVersion: app?.browserVersion || '',
  browserName: app?.browserName || '',
  osName: app?.osName || '',
  osVersion: app?.osVersion || '',
  contentProviderId: app?.contentProviderId || '',
  userAgent: app?.userAgent || '',
})

const getLocation = (location: {
  country: string
  city: string
  latitude: number
  longitude: number
}): string => {
  return location ? Object.values(location).join(', ') : ''
}

const getAnalyticsFormattedAccData = (
  userInfo: OVPUserInfo | null
): BEAnalyticsInfoOVP => ({
  networkIp: userInfo?.ip || '',
  location: userInfo ? getLocation(userInfo?.location) : '',
  contentUserSubscriberId: userInfo?.userGuid || '', // userGuid, get from BE
  contentUserAccountNumber: userInfo?.profile?.birthday || '',
  contentUserProfileId: userInfo?.profile?.gender || '',
  preferredLanguage: userInfo?.primaryLanguageIso?.toUpperCase() || '',
})

const getSessionId = (session: OVPSessionData | null): string =>
  session?.id || ''

// ************* session_start *****************
export const sendAnalyticsSessionStart: EventCallable<{
  customErrorCode: string
}> = createEvent()

export interface AnalyticsInfoSessionStart extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.sessionStart
  customErrorCode: string
  sessionId: string
}

export const sendAnalyticsSessionStartFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoSessionStart
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsSessionStart,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    { customErrorCode }
  ) => ({
    domain: analyticsDomain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.sessionStart,
      sessionId: getSessionId(session),
      windowSessionGuid,
      customErrorCode,
    } as AnalyticsInfoSessionStart,
  }),
  target: sendAnalyticsSessionStartFx,
})

// ************* session_stop *****************
export const sendAnalyticsSessionStop: EventCallable<void> = createEvent()

export interface AnalyticsInfoSessionStop extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.sessionEnd
  sessionId: string
}

export const sendAnalyticsSessionStopFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoSessionStop
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsSessionStop,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: ({ analyticsDomain, device, account, session, windowSessionGuid }) => ({
    domain: analyticsDomain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.sessionEnd,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoSessionStop,
  }),
  target: sendAnalyticsSessionStopFx,
})

// ************* favorite_add *****************
export const sendAnalyticsFavoriteAdd: EventCallable<{
  contentId: number
  contentType: ContentType
  contentUrl?: string
  contentTitle?: string
  creatorId?: number | string
}> = createEvent()

export interface AnalyticsInfoFavoriteAdd extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.favoriteAdd
  sessionId: string
}

export const sendAnalyticsFavoriteAddFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoFavoriteAdd
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsFavoriteAdd,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    { contentId, contentType, contentUrl, creatorId, contentTitle }
  ) => ({
    domain: analyticsDomain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      contentId: String(contentId),
      contentType,
      contentUrl,
      contentTitle,
      creatorId: String(creatorId),
      eventType: AnalyticsEventTypeEnum.favoriteAdd,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoFavoriteAdd,
  }),
  target: sendAnalyticsFavoriteAddFx,
})

sample({
  clock: base.addToFavoritesFx,
  filter: $enabledAnalytics,
  fn: ({ id, contentTitle, creatorId, contentUrl }) => ({
    contentId: id,
    contentType: ContentType.movie,
    contentTitle,
    creatorId,
    contentUrl,
  }),
  target: sendAnalyticsFavoriteAdd,
})

sample({
  clock: liveChannelBase.addToFavoritesFx,
  filter: $enabledAnalytics,
  fn: ({ id, contentTitle, contentUrl, creatorId }) => ({
    contentId: id,
    contentType: ContentType.live,
    contentTitle,
    creatorId,
    contentUrl,
  }),
  target: sendAnalyticsFavoriteAdd,
})

// ************* favorite_remove *****************
export const sendAnalyticsFavoriteRemove: EventCallable<{
  contentId: number
  contentType: ContentType
  contentUrl?: string
  contentTitle?: string
  creatorId?: number | string
}> = createEvent()

export interface AnalyticsInfoFavoriteRemove extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.favoriteRemove
  sessionId: string
}

export const sendAnalyticsFavoriteRemoveFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoFavoriteRemove
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsFavoriteRemove,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    { contentId, contentType, contentUrl, contentTitle, creatorId }
  ) => ({
    domain: analyticsDomain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      contentId: String(contentId),
      contentType,
      contentUrl,
      contentTitle,
      creatorId: String(creatorId),
      eventType: AnalyticsEventTypeEnum.favoriteRemove,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoFavoriteRemove,
  }),
  target: sendAnalyticsFavoriteRemoveFx,
})

sample({
  clock: base.removeFromFavoritesFx,
  filter: $enabledAnalytics,
  fn: ({ id, contentTitle, creatorId, contentUrl }) => ({
    contentId: id,
    contentType: ContentType.movie,
    contentTitle,
    creatorId,
    contentUrl,
  }),
  target: sendAnalyticsFavoriteRemove,
})

sample({
  clock: liveChannelBase.removeFromFavoritesFx,
  filter: $enabledAnalytics,
  fn: ({ id, contentTitle, contentUrl, creatorId }) => ({
    contentId: id,
    contentType: ContentType.live,
    contentTitle,
    contentUrl,
    creatorId,
  }),
  target: sendAnalyticsFavoriteRemove,
})

// ************* play_start *****************
export const sendAnalyticsStartWatching: EventCallable<VideoSourceInfoOVP> =
  createEvent()

export interface AnalyticsInfoStartWatching extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.playStart
  sessionId: string
}

export const sendAnalyticsStartWatchingFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoStartWatching
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsStartWatching,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    videoSourceInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...videoSourceInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.playStart,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoStartWatching,
  }),
  target: sendAnalyticsStartWatchingFx,
})

// ************* play_stop *****************
export const sendAnalyticsStopPauseWatching: EventCallable<VideoSourceInfoOVP> =
  createEvent()

export interface AnalyticsInfoStopWatching extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.playStop
  sessionId: string
}

export const sendAnalyticsStopPauseWatchingFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoStopWatching
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsStopPauseWatching,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    videoSourceInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...videoSourceInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.playStop,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoStopWatching,
  }),
  target: sendAnalyticsStopPauseWatchingFx,
})

// ************* player_watching *****************
export const sendAnalyticsPlayerWatching: EventCallable<VideoSourceInfoOVP> =
  createEvent()

export interface AnalyticsInfoPlayerWatching extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.playerWatching
  sessionId: string
}

export const sendAnalyticsPlayerWatchingFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoPlayerWatching
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsPlayerWatching,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    videoSourceInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...videoSourceInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.playerWatching,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoPlayerWatching,
  }),
  target: sendAnalyticsPlayerWatchingFx,
})

// ************* player_event *****************
export const sendAnalyticsPlayerEvent: EventCallable<VideoSourceInfoOVP> =
  createEvent()

export interface AnalyticsInfoPlayerEvent extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.playerEvent
  sessionId: string
}

export const sendAnalyticsPlayerEventFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoPlayerEvent
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsPlayerEvent,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    videoSourceInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...videoSourceInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.playerEvent,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoPlayerEvent,
  }),
  target: sendAnalyticsPlayerEventFx,
})

// ************* player_open *****************
export const sendAnalyticsPlayerOpen: EventCallable<IContentInfoOVP> =
  createEvent()

export interface AnalyticsInfoPlayerOpen extends AnalyticsPlayerOpenInfoOVP {
  eventType: AnalyticsEventTypeEnum.playerOpen
  sessionId: string
}

export const sendAnalyticsPlayerOpenFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoPlayerOpen
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsPlayerOpen,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    contentInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...contentInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.playerOpen,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoPlayerOpen,
  }),
  target: sendAnalyticsPlayerOpenFx,
})

// ************* search *****************
export const sendAnalyticsSearch: EventCallable<ISearchInfoOVP> = createEvent()

export interface AnalyticsInfoSearch extends AnalyticsSearchInfoOVP {
  eventType: AnalyticsEventTypeEnum.search
  sessionId: string
}

export const sendAnalyticsSearchFx: Effect<
  {
    domain: string | null
    data: AnalyticsInfoSearch
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsSearch,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    searchInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...searchInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.search,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as AnalyticsInfoSearch,
  }),
  target: sendAnalyticsSearchFx,
})

sample({
  clock: searchBase.getFx,
  filter: $enabledAnalytics,
  fn: (data) => ({
    searchInput: data.q,
    contentSearchType: 'globalSearch',
  }),
  target: sendAnalyticsSearch,
})

// ************* page_open *****************
export const sendAnalyticsPageOpen: EventCallable<IUTMParams & IURLData> =
  createEvent()

export interface IAnalyticsInfoPageOpen extends AnalyticsGenericInfoOVP {
  eventType: AnalyticsEventTypeEnum.pageOpen
  sessionId: string
}

export const sendAnalyticsPageOpenFx: Effect<
  {
    domain: string | null
    data: IAnalyticsInfoPageOpen
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsPageOpen,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    urlData
  ) => ({
    domain: analyticsDomain,
    data: {
      ...urlData,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.pageOpen,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as IAnalyticsInfoPageOpen,
  }),
  target: sendAnalyticsPageOpenFx,
})

// ************* epg_view *****************
export const sendAnalyticsEpgView: EventCallable<IEpgInfoOVP> = createEvent()

export interface IAnalyticsInfoEpgView extends AnalyticsEpgViewInfoOVP {
  eventType: AnalyticsEventTypeEnum.epgView
  sessionId: string
}

export const sendAnalyticsEpgViewFx: Effect<
  {
    domain: string | null
    data: IAnalyticsInfoEpgView
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsEpgView,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    epgInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...epgInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.epgView,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as IAnalyticsInfoEpgView,
  }),
  target: sendAnalyticsEpgViewFx,
})

// ************* epg_category *****************
export const sendAnalyticsEpgCategory: EventCallable<IEpgCategoryInfoOVP> =
  createEvent()

export interface IAnalyticsEpgCategoryInfo extends AnalyticsEpgCategoryInfoOVP {
  eventType: AnalyticsEventTypeEnum.epgCategory
  sessionId: string
}

export const sendAnalyticsEpgCategoryFx: Effect<
  {
    domain: string | null
    data: IAnalyticsEpgCategoryInfo
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsEpgCategory,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    epgInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...epgInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.epgCategory,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as IAnalyticsEpgCategoryInfo,
  }),
  target: sendAnalyticsEpgCategoryFx,
})

// ************* epg_channel *****************
export const sendAnalyticsEpgChannel: EventCallable<IEpgChannelInfoOVP> =
  createEvent()

export interface IAnalyticsEpgChannelInfo extends AnalyticsEpgChannelInfoOVP {
  eventType: AnalyticsEventTypeEnum.epgChannel
  sessionId: string
}

export const sendAnalyticsEpgChannelFx: Effect<
  {
    domain: string | null
    data: IAnalyticsEpgChannelInfo
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsEpgChannel,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    epgInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...epgInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.epgChannel,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as IAnalyticsEpgChannelInfo,
  }),
  target: sendAnalyticsEpgChannelFx,
})

// ************* epg_program *****************
export const sendAnalyticsEpgProgram: EventCallable<IEpgProgramInfoOVP> =
  createEvent()

export interface IAnalyticsEpgProgramInfo extends AnalyticsEpgProgramInfoOVP {
  eventType: AnalyticsEventTypeEnum.epgProgram
  sessionId: string
}

export const sendAnalyticsEpgProgramFx: Effect<
  {
    domain: string | null
    data: IAnalyticsEpgProgramInfo
  },
  void
> = createEffect()

sample({
  clock: sendAnalyticsEpgProgram,
  source: {
    analyticsDomain: $analyticsDomain,
    device: $applicationInfo,
    account: $userInfo,
    session: $sessionInfo,
    windowSessionGuid: $windowSessionGuid,
  },
  fn: (
    { analyticsDomain, device, account, session, windowSessionGuid },
    epgInfo
  ) => ({
    domain: analyticsDomain,
    data: {
      ...epgInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.epgProgram,
      sessionId: getSessionId(session),
      windowSessionGuid,
    } as IAnalyticsEpgProgramInfo,
  }),
  target: sendAnalyticsEpgProgramFx,
})
