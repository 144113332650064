import { api } from '@setplex/tria-api'
import { type EffectParams } from 'effector'

export type TvChannelPageMeta = {
  isLast: boolean
  hasMore: boolean
  dirty?: boolean
}

// made-up params, which is added to avoid importing CategoryId from categories entity
type ByCategoryIdParams = {
  byCategoryId: number
}
export type GetAllTvChannelsByPageParams = EffectParams<
  typeof api.live.base.getLiveChannelsFx
>
export type LoadPageParams = GetAllTvChannelsByPageParams & ByCategoryIdParams

export const LOAD_MORE_CHANNELS_LIMIT = 20
