import { epgCategoriesModel } from '$/epg/categories'
import { PATH } from '@/constants'
import { A } from '@mobily/ts-belt'
import { createEvent, createStore, sample } from 'effector'
import { delay } from 'patronum'
import { model as router } from '~/core/router'
import { lookbehind } from '~/shared/effector'
import { aye } from '~/shared/helpers'
import { QUERY_PARAM } from './index.h'
import { getCategoryIdFromQuery } from './lib'

export const autoSetActiveId = createEvent<{
  query?: URLSearchParams
}>()
export const setActiveId = createEvent<number | null>()

export const $activeId = createStore<number | null>(null)
  .on(setActiveId, (_, id) => id)
  .on(
    autoSetActiveId,
    (current, params) => getCategoryIdFromQuery(params?.query) ?? current
  )

//
// persist active category id in query string
//

export const pushActiveIdToQuery = createEvent<number | null>()

lookbehind({
  source: $activeId,
  clear: autoSetActiveId,
  within: 0,
  target: pushActiveIdToQuery,
})

sample({
  clock: pushActiveIdToQuery,
  source: router.$pathname,
  filter: (pathname) =>
    aye(pathname?.startsWith(`${PATH.LIVE}${PATH.CATEGORIES}`)),
  fn: (_, id) => {
    return { [QUERY_PARAM]: id == null ? null : String(id) }
  },
  target: router.navigatePushQuery,
})

//
//
//

// select first category if there is no active category yet
sample({
  clock: autoSetActiveId,
  source: {
    activeId: $activeId,
    madeup: epgCategoriesModel.$madeup,
    loaded: epgCategoriesModel.$loaded,
  },
  filter: ({ activeId, madeup, loaded }) => {
    const hasCategories = madeup.length + loaded.length > 0

    // if there are categories and there is no active category id set in url
    return hasCategories && activeId == null
  },

  fn: ({ madeup, loaded }) => {
    // set category 'All', or first madeup category, or first regular category
    return madeup[1]?.id ?? madeup[0]?.id ?? loaded[0].id!
  },
  target: setActiveId,
})

const autoSetActiveIdEpg = createEvent<{
  query?: URLSearchParams
}>()

sample({
  clock: autoSetActiveId,
  source: router.$pathname,
  filter: (pathname) =>
    aye(pathname?.startsWith(`${PATH.LIVE}${PATH.CATEGORIES}`)),
  fn: (_, params) => params,
  target: autoSetActiveIdEpg,
})

const autoSetActiveIdDelay = delay({
  source: autoSetActiveIdEpg,
  timeout: 1000,
})

// select first category if category id is not found in categories list

// epgCategoriesModel.$madeup doesn't have time to fully form when the event is triggered.
// Which leads to a reset to the first category.
// TODO: sometimes category All channels are not ready and set category that not expected - redo, need to wait channels
sample({
  clock: autoSetActiveIdDelay,
  source: {
    activeId: $activeId,
    madeup: epgCategoriesModel.$madeup,
    loaded: epgCategoriesModel.$loaded,
  },
  filter: ({ activeId, madeup, loaded }) => {
    const hasCategories = madeup.length + loaded.length > 0
    const categories = A.concat(madeup, loaded)

    // if there are categories, and there is active category id set in url,
    // but no such category in madeup+loaded found
    return (
      hasCategories &&
      activeId != null &&
      A.all(categories, ({ id }) => id !== activeId)
    )
  },

  fn: ({ madeup, loaded }) => {
    // set category 'All', or first madeup category, or first regular category
    return madeup[1]?.id ?? madeup[0]?.id ?? loaded[0].id!
  },
  target: [setActiveId, pushActiveIdToQuery], // also forcelly update query string
})
