import { createEffect, createStore } from 'effector'
import type { TimeRange } from '../../interfaces/epg'
import type { ApiResponseError } from '../../interfaces/errors'
import { stateful } from '../../lib/stateful'
import type {
  EpgInfo,
  EpgInfoDto,
  LiveChannel,
  LiveChannelCategory,
  LiveChannelWithEpg,
  TvChannelWithEpg,
  TvEpg,
  TvEpgApiDto,
} from './index.h'

export {
  type EpgInfo,
  type EpgInfoDto,
  type LiveChannel,
  type LiveChannelCategory,
  type LiveChannelWithEpg,
  type TimeRange,
  type TvChannelWithEpg,
  type TvEpg,
  type TvEpgApiDto,
}

type GetOneParams = { id?: number }
export type PlaybackUrl = { playbackUrl: string }
type CategoryParams = { withChannelIds?: 1 | 0 }
type PageParams = { limit?: number; offset?: number }
type GetManyParams = GetOneParams & PageParams
type RequestHeaders = { headers: Headers }

type FavoriteParams = {
  id: number
  contentTitle?: string
  contentUrl?: string
  isFavorite: boolean
  creatorId?: string
}

interface LiveChannelCategories extends Array<LiveChannelCategory> {
  total: number
}

// *
// * base API effects
// *
export const base = {
  getOneFx: createEffect<GetOneParams, LiveChannel>(),
  getOnePlaybackUrlFx: createEffect<
    GetOneParams & RequestHeaders,
    PlaybackUrl
  >(),
  getLiveChannelsFx: createEffect<PageParams, LiveChannel[]>(),
  getLiveChannelsListFx: createEffect<PageParams, LiveChannel[]>(),
  getLiveChannelsCategoriesFx: createEffect<
    CategoryParams & PageParams,
    LiveChannelCategories
  >(),
  getLiveChannelsByCategoryFx: createEffect<GetManyParams, LiveChannel[]>(),
  getLiveChannelsByTagFx: createEffect<GetManyParams, LiveChannel[]>(),
  getLiveChannelsByCollectionFx: createEffect<GetManyParams, LiveChannel[]>(),
  getFavoritesFx: createEffect<PageParams, LiveChannel[]>(),
  addToFavoritesFx: createEffect<FavoriteParams, void>(),
  removeFromFavoritesFx: createEffect<FavoriteParams, void>(),
  getEpgInformationFormattedFx: createEffect<
    {
      epgIds: string[]
      channels: LiveChannel[] | TvChannelWithEpg[]
      period: TimeRange
    },
    { epg: TvEpg; period: TimeRange }
  >(),
  getOneLiveCategoryFx: createEffect<GetOneParams, LiveChannelCategory>(),
}

export const pageable = (initial?: PageParams) =>
  stateful({
    effect: base.getLiveChannelsFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableList = (initial?: PageParams) =>
  stateful({
    effect: base.getLiveChannelsListFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableChannelsByCollection = (initial?: GetManyParams) =>
  stateful({
    effect: base.getLiveChannelsByCollectionFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableChannelsByCategory = (initial?: GetManyParams) =>
  stateful({
    effect: base.getLiveChannelsByCategoryFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableChannelsByTag = (initial?: GetManyParams) =>
  stateful({
    effect: base.getLiveChannelsByTagFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableCategories = (initial?: PageParams) =>
  stateful({
    effect: base.getLiveChannelsCategoriesFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableFavorites = (initial?: PageParams) =>
  stateful({
    effect: base.getFavoritesFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void) => ({}),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const error = {
  getOneFx: createStore<null | ApiResponseError>(null).on(
    base.getOneFx.fail,
    (_, { error }) => {
      // @ts-ignore because error does not exist in type Error
      const response = error?.error?.response
      if (!response) return

      return {
        code: response.status,
        message: response.statusText,
      }
    }
  ),
}
