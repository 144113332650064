import { type LiveChannelCategory } from '@setplex/tria-api'

// TODO: Remove Featured only after all EPG feature will be ready to avoid bugs when get code from Tria

/* Used also as `sortOrder` value */
export enum CategoryType {
  Favorite = -3,
  All = -2,
  Featured = -1,
  Regular = 0,
}

export type TvCategoryEx = LiveChannelCategory & {
  type: CategoryType
}

export enum CategoryId {
  Favorite = -2,
  Featured = -1,
  All = 0,
  Regular = NaN, // real category id is used instead
}

export enum CategoryTitle {
  Favorite = 'My List',
  All = 'All',
}

export const LOAD_MORE_CATEGORIES_LIMIT = 10
