import { type LiveChannelFormatted } from '$/channels'
import {
  CARDS_ASPECT_RATIO,
  CardsBreakpoints,
  FONTS,
  LiveChannelCard,
  RowContainerTemplate,
  RowControlsType,
  type IRowContainerProps,
} from '@setplex/arcane'
import { MediaTypes } from '@setplex/tria-api'
import { useUnit } from 'effector-react'
import { JSX, useCallback, type SyntheticEvent } from 'react'
import { model as session } from '~/entities/session'
import { model as favoritesModel } from '~/features/favorites'
import { addGoogleAnalyticsPrefixWithAuth } from '~/shared/helpers'
import { LiveChannelRowCardsCount } from '../../constants'

interface IRowProps {
  id?: number | string
  content?: LiveChannelFormatted[]
  title: IRowContainerProps['title']
  background?: IRowContainerProps['background']
  cardsCount?: LiveChannelRowCardsCount
  isSeeAllBtnShown?: boolean
  seeAllBtnClick?: ({ id, title }?: { id: number; title: string }) => void
  className?: string
  handleClick?: (data: string) => void
  onClickChannelCard?: () => void
}

ChannelRow.displayName = 'ChannelRow'
export function ChannelRow({
  id: rowId,
  content,
  title = '',
  background,
  className = 'channel-row',
  cardsCount = LiveChannelRowCardsCount.DEFAULT,
  isSeeAllBtnShown,
  seeAllBtnClick,
  handleClick,
  onClickChannelCard,
}: IRowProps): JSX.Element | null {
  const contentLength = content?.length ?? 0
  const isMoreThanOnePage = isSeeAllBtnShown ?? contentLength > cardsCount
  const favorite = useUnit(favoritesModel.$favorites)
  const handleFavoriteBtnClick = useUnit(favoritesModel.handleFavoriteBtnClick)
  const sliced = content?.slice(0, cardsCount) ?? []
  const slicedLength = sliced.length
  const isAuthenticated = useUnit(session.$isAuthenticated)

  const onClick = () => {
    handleClick?.(title)
  }

  const handleSeeAllBtnClick = useCallback(() => {
    if (!seeAllBtnClick) return

    if (typeof rowId === 'number') {
      return seeAllBtnClick({ id: rowId, title })
    }
    return seeAllBtnClick() // Case if navigate to favorites for example
  }, [rowId, title, seeAllBtnClick])

  return slicedLength ? (
    <RowContainerTemplate
      className={className}
      title={title}
      itemCount={slicedLength}
      background={background}
      breakpoints={CardsBreakpoints.ChannelCard}
      cardAspectRatio={CARDS_ASPECT_RATIO.HORIZONTAL_DEFAULT}
      controlsType={RowControlsType.inRowFluid}
      isMoreThanOnePage={isMoreThanOnePage}
      onSeeAllClick={handleSeeAllBtnClick}
      handleClick={onClick}
      titleFont={FONTS.SubtitleXlBold}
    >
      {sliced.map((card, index) => {
        const {
          id,
          logo,
          link,
          title: channelTitle,
          number: channelNumber,
        } = card

        const isFavorite = Boolean(favorite.live_channel[id])
        const onClick = (e: SyntheticEvent) => {
          e.preventDefault()
          e.stopPropagation()

          handleFavoriteBtnClick?.({
            id,
            isFavorite: !isFavorite,
            contentType: MediaTypes.LIVE_CHANNEL,
            creatorId: '',
            contentTitle: channelTitle,
          })
        }
        const rowIdGA = rowId ? `row-${rowId}` : 'row'
        const idForGA = addGoogleAnalyticsPrefixWithAuth(
          `add-fav-channel-${rowIdGA}-${id}`,
          isAuthenticated
        )

        return (
          <a
            key={`${index}${id}`}
            href={link}
            id={addGoogleAnalyticsPrefixWithAuth(
              `channel-play-${rowIdGA}-${id}`,
              isAuthenticated
            )}
          >
            <LiveChannelCard
              idForGA={idForGA}
              posterUrl={logo}
              isFavorite={isFavorite}
              handleFavoriteClick={onClick}
              onClick={onClickChannelCard}
              alt={`"${channelTitle}" channel logo`}
              channelNumber={String(channelNumber)}
            />
          </a>
        )
      })}
    </RowContainerTemplate>
  ) : null
}
