/* eslint-disable @typescript-eslint/no-explicit-any */
import { type RouterResponse } from '!/router'
import { isArray } from '@/helpers'
import {
  createElement,
  JSX,
  type ComponentClass,
  type FunctionComponent,
} from 'react'

interface Props {
  route: RouterResponse | undefined
}

Page.displayName = 'Page'
export function Page({ route }: Props): JSX.Element | null {
  if (!route || !route.component) {
    return null
  }

  return (
    <>
      {isArray(route.component)
        ? route.component.map((component) => render(component, route.props))
        : render(route.component, route.props)}
    </>
  )
}

/**
 * Creates page single component, using `displayName` as a key
 */
function render(
  component?: FunctionComponent<any> | ComponentClass<any, any>,
  props?: any
) {
  if (!component) return null
  const key: string = component.displayName || component.name
  return createElement(component, { key, ...props })
}
