import {
  CategoryTitle,
  CategoryType,
  type TvCategoryEx,
} from '$/epg/categories'
import { produce } from 'immer'

export const addTitleToCategory = (category: TvCategoryEx) =>
  produce(category, (draft) => {
    if (draft.type === CategoryType.Favorite) {
      draft.title = CategoryTitle.Favorite
    } else if (draft.type === CategoryType.All) {
      draft.title = CategoryTitle.All
    }
  })
