import { model as router } from '!/router'
import { concatById, F, T, toArray } from '@/helpers'
import { A, D } from '@mobily/ts-belt'
import { api, type LiveChannel } from '@setplex/tria-api'
import { attach, createEvent, createStore, sample } from 'effector'
import { selectEpgCategoryModel } from '~/features/epg/categories/select'
import { PATH } from '~/shared/constants'
import { CategoryId } from '../categories'
import {
  LOAD_MORE_CHANNELS_LIMIT,
  type LoadPageParams,
  type TvChannelPageMeta,
} from './index.h'

// all loaded channels, mapped by id
export const addToAll = createEvent<LiveChannel | LiveChannel[]>()
export const $all = createStore<[Map<number, LiveChannel>]>([new Map()])

// loaded channels by categories (All, Favorite, regular), with metadata (isLast, hasMore)
export const $byCategory = createStore<
  [Map<number, LiveChannel[]>, Map<number, TvChannelPageMeta>]
>([new Map(), new Map()])

// load single channel by id
export const loadOneFx = attach({
  mapParams: (id: number) => ({ id }),
  effect: api.live.base.getOneFx,
})

// load channels by category All by page
export const loadAllPageFx = attach({
  mapParams: (params: LoadPageParams) => D.deleteKey(params, 'byCategoryId'),
  effect: api.live.base.getLiveChannelsFx,
})

// load channels by regular category by page
export const loadByCategoryPageFx = attach({
  mapParams: (params: LoadPageParams) => D.deleteKey(params, 'byCategoryId'),
  effect: api.live.base.getLiveChannelsByCategoryFx,
})

// load favorites channels by page
export const loadFavoritesPageFx = attach({
  mapParams: (params: LoadPageParams) => D.deleteKey(params, 'byCategoryId'),
  effect: api.live.base.getFavoritesFx,
})

// store that indicates if favorite channels should be loaded again (if it was changes in favorites)
const $favChannelsShouldBeLoadedAgain = createStore(true)
  .on(
    [
      api.live.base.removeFromFavoritesFx.finally,
      api.live.base.addToFavoritesFx.finally,
    ],
    T
  )
  .on(loadFavoritesPageFx.finally, F)

// if there were changes in favorites reset favorite category to reload data with new added or removed channels at other tabs or pages
sample({
  clock: selectEpgCategoryModel.setActiveId,
  source: {
    byCategory: $byCategory,
    categoryId: selectEpgCategoryModel.$activeId,
    favChannelsShouldBeLoadedAgain: $favChannelsShouldBeLoadedAgain,
  },
  filter: ({ categoryId, favChannelsShouldBeLoadedAgain }) =>
    categoryId === CategoryId.Favorite && favChannelsShouldBeLoadedAgain,
  fn: ({ byCategory: [channels, meta] }) => {
    channels.delete(CategoryId.Favorite)
    meta.delete(CategoryId.Favorite)
    return [channels, meta]
  },
  target: $byCategory,
})

// if there were changes in favorites reset favorite category when return/open Epg page first time with preselected favorite category
sample({
  clock: selectEpgCategoryModel.autoSetActiveId,
  source: {
    byCategory: $byCategory,
    categoryId: selectEpgCategoryModel.$activeId,
    prevRoute: router.$previous,
    favChannelsShouldBeLoadedAgain: $favChannelsShouldBeLoadedAgain,
  },
  filter: ({ categoryId, prevRoute, favChannelsShouldBeLoadedAgain }) =>
    categoryId === CategoryId.Favorite &&
    prevRoute?.pathname !== `${PATH.LIVE}${PATH.CATEGORIES}` &&
    favChannelsShouldBeLoadedAgain,
  fn: ({ byCategory: [channels, meta] }) => {
    channels.delete(CategoryId.Favorite)
    meta.delete(CategoryId.Favorite)

    return [channels, meta]
  },
  target: $byCategory,
})

// when new channels by All category are loaded - add them to requested category in map
sample({
  clock: loadAllPageFx.done,
  source: $byCategory,
  fn: ([channels, meta], { params, result }) => {
    const categoryId = params.byCategoryId
    const existing = channels.get(categoryId)
    const isLast =
      Number(params?.offset) + LOAD_MORE_CHANNELS_LIMIT >= result?.total

    channels.set(categoryId, concatById(existing)(result))
    meta.set(categoryId, { isLast, hasMore: !isLast })

    return [channels, meta] as const
  },
  target: $byCategory,
})

// when new channels by regular category are loaded - add them to requested category in map
sample({
  clock: loadByCategoryPageFx.done,
  source: $byCategory,
  fn: ([channels, meta], { params, result }) => {
    const categoryId = params.byCategoryId
    const existing = channels.get(categoryId)
    const isLast =
      Number(params?.offset) + LOAD_MORE_CHANNELS_LIMIT >= result?.total

    channels.set(categoryId, concatById(existing)(result))
    meta.set(categoryId, { isLast, hasMore: !isLast })

    return [channels, meta] as const
  },
  target: $byCategory,
})

// when new Favorites channels are loaded - add them to requested category in map
sample({
  clock: loadFavoritesPageFx.done,
  source: $byCategory,
  fn: ([channels, meta], { params, result }) => {
    const categoryId = params.byCategoryId
    const existing = channels.get(categoryId)
    const isLast =
      Number(params?.offset) + LOAD_MORE_CHANNELS_LIMIT >= result?.total

    channels.set(categoryId, concatById(existing)(result))
    meta.set(categoryId, { isLast, hasMore: !isLast })

    return [channels, meta] as const
  },
  target: $byCategory,
})

// update channels in byCategory map, if we got new channel
// * listen for ALL channels responses (not only on epg page)
sample({
  clock: api.live.base.getOneFx.doneData,
  source: $byCategory,
  fn: ([channels, meta], result) => {
    const got = [result]
    const updated = new Map(A.map(got, (channel) => [channel.id, channel]))
    for (const [categoryId, categoryChannels] of channels) {
      let changed = false
      for (let i = 0; i < categoryChannels.length; i++) {
        const channel = categoryChannels[i]
        const updatedChannel = updated.get(channel.id)
        if (updatedChannel != null) {
          changed = true
          categoryChannels.splice(i, 1, updatedChannel)
        }
      }
      if (changed) channels.set(categoryId, categoryChannels.slice())
    }
    return [channels, meta] as const
  },
  target: $byCategory,
})

// add loaded channels to all channels map
// * listen for ALL pageable responses (not only on epg page)
sample({
  clock: [
    api.live.base.getLiveChannelsFx.doneData,
    api.live.base.getLiveChannelsByCategoryFx.doneData,
    api.live.base.getFavoritesFx.doneData,
  ],
  fn: (result) => result,
  target: addToAll,
})

// add loaded channels to all channels map
// * listen for ALL channels responses (not only on epg page)
sample({
  clock: api.live.base.getOneFx.doneData,
  target: addToAll,
})

// update all channels map
sample({
  clock: addToAll,
  source: $all,
  fn: ([all], channels) => {
    for (const channel of toArray(channels)) {
      all.set(channel.id, channel) // update or set channel by id
    }
    return [all] as const
  },
  target: $all,
})

// store that indicates if any channel loading request was completed
export const $allIsReady = createStore<boolean>(false).on(
  [
    api.live.base.getLiveChannelsFx.finally,
    api.live.base.getLiveChannelsByCategoryFx.finally,
    api.live.base.getFavoritesFx.finally,
    api.live.base.getOneFx.finally,
  ],
  T
)
