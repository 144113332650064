import { isbot } from 'isbot'
import { UAParser } from 'ua-parser-js'

export const app = {
  name: 'UVOtv',
  domain: location.host,
} as const

//
// get information from user-agent string
//

export const ua: UAParser.IResult = UAParser(navigator.userAgent)
export const isBot = isbot(navigator.userAgent)

export const os = {
  name: ua.os.name,
  version: ua.os.version,
} as const

// TODO: should use feature detection, in case user agent string is spuffed
export const browser = {
  name: ua.browser.name,
  version: ua.browser.version,
} as const

export const device = {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  // > If the device is large enough that it's not marked with Mobi,
  // > you should serve your desktop site (which, as a best practice,
  // > should support touch input anyway, as more desktop machines are appearing with touchscreens).
  type: /Mobi/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
} as const

// TODO: could check https://github.com/fingerprintjs/fingerprintjs repo for more references

//
// EME support
//

export const drms: string[] = []

// asynchronously fill supported DRM systems
;(async () => {
  const emeSupport =
    'MediaKeys' in window ||
    'WebKitMediaKeys' in window ||
    'MSMediaKeys' in window

  const requestMediaKeySystemAccessSupport =
    'requestMediaKeySystemAccess' in window.navigator

  if (!emeSupport || !requestMediaKeySystemAccessSupport) {
    return
  }

  const keySystems = {
    widevine: ['com.widevine.alpha'],
    fairplay: [
      'com.apple.fairplay',
      'com.apple.fps',
      'com.apple.fps.1_0',
      'com.apple.fps.2_0',
      'com.apple.fps.3_0',
    ],
    playready: [
      'com.microsoft.playready',
      'com.youtube.playready',
      'com.microsoft.playready.recommendation',
      'com.microsoft.playready.hardware',
    ],
    primetime: ['com.adobe.primetime', 'com.adobe.access'],
    clearkey: ['org.w3.clearkey', 'webkit-org.w3.clearkey'],
  }

  const result: string[] = []
  for (const [name, keys] of Object.entries(keySystems)) {
    for (const key of keys) {
      try {
        // prettier-ignore
        await navigator.requestMediaKeySystemAccess(key, [{
          initDataTypes: ['cenc'],
          // ideally should be even with real used streams
          videoCapabilities: [{ contentType: 'video/mp4;codecs="avc1.42E01E"' }],
          audioCapabilities: [{ contentType: 'audio/mp4;codecs="mp4a.40.2"' }],
        }])
        result.push(name.toUpperCase())
        break
      } catch (e) {
        // catch error
      }
    }
  }
  Array.prototype.push.apply(drms, result)
})()
