import {
  InfoBlockSignUp,
  SignUpContainerForm,
  SignUpWrapperSC,
  type IAuthLayoutTemplateProps,
} from '@setplex/arcane'
import { JSX } from 'react'

AuthFormWrapper.displayName = 'AuthFormWrapper'
export function AuthFormWrapper({
  className,
  children,
}: IAuthLayoutTemplateProps): JSX.Element {
  return (
    <SignUpWrapperSC className={className}>
      <SignUpContainerForm>
        <InfoBlockSignUp
          title='Join the UVOtv community'
          description='UVOtv gives you instant access to over
            5,000 movies and shows from all around the world at no cost to you!'
          ratingValue={5}
          imageUrl='/img/sign-up-avatar.png'
        />
        {children}
      </SignUpContainerForm>
    </SignUpWrapperSC>
  )
}
