import { QUERY_PARAM } from './index.h'

export function getCategoryIdFromQuery(
  query?: URLSearchParams | undefined
): number | undefined {
  const id = query?.get(QUERY_PARAM)
  if (id == null) return undefined
  const idn = Number(id)
  if (isNaN(idn)) return undefined
  return idn
}
