import {
  CategoryId,
  epgCategoriesModel,
  type TvCategoryEx,
} from '$/epg/categories'
import { A } from '@mobily/ts-belt'
import { createEvent, createStore, restore, sample } from 'effector'
import { model as session } from '~/entities/session'
import { loadEpgCategoriesModel } from '~/features/epg/categories/load'
import { loadMoreEpgCategoriesModel } from '~/features/epg/categories/load-more'
import {
  getCategoryIdFromQuery,
  selectEpgCategoryModel,
} from '~/features/epg/categories/select'
import { aye, F, T } from '~/shared/helpers'
import { model as signInModel } from '~/widgets/auth/sign-in'
import * as signUpModel from '~/widgets/auth/sign-up/model'
import { addTitleToCategory } from './helpers'

// load initial categories list
export const init = createEvent<{ query: URLSearchParams }>()

// reset widget on epg page left
export const left = createEvent()

// reset widget on sign out
export const reset = createEvent()

// load more categories
export const more = loadMoreEpgCategoriesModel.load

// select category
export const select = selectEpgCategoryModel.setActiveId

// categories to show in widget
export const $categories = createStore<TvCategoryEx[]>([])

// flag to indicate that there are no more categories to load
export const $isLast = epgCategoriesModel.$isLast

// currently selected category id
export const $selected = selectEpgCategoryModel.$activeId

// load initial categories list by query in url
sample({
  clock: init,
  fn: ({ query }) => ({ query, category: getCategoryIdFromQuery(query) }),
  target: loadEpgCategoriesModel.load,
})

// auto select first or url-chosen category
sample({
  clock: [init, loadEpgCategoriesModel.$ready],
  source: {
    init: restore(init, null).reset(left, reset),
    ready: loadEpgCategoriesModel.$ready,
  },
  filter: ({ init, ready }) => init != null && ready,

  fn: ({ init }) => init!,
  target: selectEpgCategoryModel.autoSetActiveId,
})

// wait for initial categories list to be loaded, then concat made-up and loaded categories
// also update categories list on load more categories event
sample({
  clock: [
    loadEpgCategoriesModel.$ready,
    epgCategoriesModel.$loaded,

    // TODO: remove this line if featured request will be faster
    //       now this allows to show featured channels dynamically when they are loaded
    epgCategoriesModel.$madeup,
  ],
  source: {
    madeup: epgCategoriesModel.$madeup,
    loaded: epgCategoriesModel.$loaded,
  },
  filter: loadEpgCategoriesModel.$ready,
  fn: ({ madeup, loaded }) =>
    A.concat(A.map(madeup, addTitleToCategory), loaded),
  target: $categories,
})

/*
 * logics for showing signInPopup after clicking category My List
 * start
 */
export const handleFavoritesCategory = createEvent()
export const $wasTryingToOpenFavoritesCategory = createStore<boolean>(false)

sample({
  clock: handleFavoritesCategory,
  target: signInModel.tryToOpenSignInPopup,
})

sample({
  clock: handleFavoritesCategory,
  filter: session.$isNotAuthenticated,
  fn: T,
  target: $wasTryingToOpenFavoritesCategory,
})

sample({
  clock: signInModel.signInFx.done,
  source: $wasTryingToOpenFavoritesCategory,
  filter: aye,
  fn: () => CategoryId.Favorite,
  target: select,
})

sample({
  clock: [
    signInModel.signInFx.done,
    signInModel.closeSignInPopup,
    signUpModel.closeSignUpPopup,
  ],
  fn: F,
  target: $wasTryingToOpenFavoritesCategory,
})

sample({
  clock: handleFavoritesCategory,
  filter: session.$isAuthenticated,
  fn: () => CategoryId.Favorite,
  target: select,
})

/* If sign out and My list tab was selected => reselect All tab */
sample({
  clock: session.$isNotAuthenticated,
  source: {
    activeId: selectEpgCategoryModel.$activeId,
    isNotAuthenticated: session.$isNotAuthenticated,
  },
  filter: ({ activeId, isNotAuthenticated }) =>
    isNotAuthenticated && activeId === CategoryId.Favorite,
  fn: () => CategoryId.All,
  target: select,
})

/*
 * logics for navigation after clicking button My List
 * end
 */

sample({
  clock: left,
  target: [
    selectEpgCategoryModel.$activeId.reinit,
    loadEpgCategoriesModel.$favoritesReady.reinit,
    epgCategoriesModel.removeFavoriteCategory,
  ],
})

sample({
  clock: reset,
  target: [
    epgCategoriesModel.$loaded.reinit,
    epgCategoriesModel.$madeup.reinit,
    epgCategoriesModel.$isLast.reinit,
    loadEpgCategoriesModel.$othersReady.reinit,
    loadEpgCategoriesModel.$favoritesReady.reinit,
    $categories.reinit,
  ],
})
