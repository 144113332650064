import { replaceSpacesWithHyphens } from './strings'

/**
 * Function replace all spaces with hyphens in string, make it lower case.
 * Used, for example, to insert hyphens into the title before inserting it into the URL
 * @param title
 */
export const formatTitleForUrl = (title: string | undefined): string => {
  if (!title) return ''
  return `${replaceSpacesWithHyphens(encodeURIComponent(title.toLowerCase()))}-`
}

/**
 * Function get substring after last hyphens in string.
 * Used to get 'id' 93 from URL /category/sports-news-93
 * @param string
 */
export const getIdFromUrl = (string = ''): number | null => {
  const id = Number(string.split('-').pop())
  return id || null
}

/**
 * Get id and/or title from url like 'award-winner-3', 'award%20winner-3', 'award%20winner', 'award-winner', '3'
 * @param string
 */
export const getDataFromUrl = (
  string = ''
): { id: number | null; title: string } => {
  if (!string) return { id: null, title: '' }

  const parts = string.split('-')
  const lastPart = parts[parts.length - 1]
  const potentialId = Number(lastPart)

  // Check if last part is a valid number (including 0)
  if (!isNaN(potentialId) && lastPart !== '') {
    return {
      id: potentialId,
      title: parts.slice(0, -1).join('-'),
    }
  }

  return {
    id: null,
    title: string,
  }
}
