export interface ApplicationInfoOVP {
  appId: string
  appName: string
  appVersion: string
  browserName: string
  browserVersion: string
  osName: string
  osVersion: string
  userAgent: string
  contentProviderId: string
}
// for start and watch

export enum PlayerEventTypeOVP {
  mute = `mute`,
  unmute = 'unmute',
  subtitle_change = 'subtitle_change',
  rewind = 'rewind',
}

// TODO: can be used one of them: PlayStartTriggerTypeOVP and PLAYBACK_TRIGGER
export enum PlayStartTriggerTypeOVP {
  playerOpen = `player_open`,
  playButton = 'play_button',
  nextChannel = 'next_channel',
  prevChannel = `previous_channel`,
  channelList = `channel_list`,
  epgBoard = `epg_board`,
}

export interface VideoSourceInfoOVP {
  // all not generic analytics params
  contentId?: string
  contentUrl?: string
  contentType?: string
  contentTitle?: string
  audioLanguage?: string
  textLanguage?: string
  startedTime?: string // read as 'event happened time'
  stoppedTime?: string // read as 'event happened time'
  duration?: string
  creatorId?: string
  playerSessionId?: string
  playerEventType?: PlayerEventTypeOVP
  programId?: string
  programTitle?: string
  playbackStartTrigger?: PlayStartTriggerTypeOVP
  categoryTitle?: string
}

type WindowSessionGuidType = { windowSessionGuid: string }

export interface IUTMParams {
  utm_source?: string | null
  utm_medium?: string | null
  utm_campaign?: string | null
  utm_term?: string | null
  utm_content?: string | null
}

export interface IURLData {
  path?: string
  entityId?: string
}

export interface AnalyticsInfoOVP
  extends VideoSourceInfoOVP,
    ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    WindowSessionGuidType,
    IUTMParams,
    IURLData {}

export interface AnalyticsGenericInfoOVP
  extends VideoSourceInfoOVP,
    ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    WindowSessionGuidType,
    IUTMParams,
    IURLData {}

export enum AnalyticsEventTypeOVP {
  playerOpen = `player_open`,
  playerWatching = 'player_watching',
  playerEvent = 'player_event',
  playStart = `play_start`,
  playStop = `play_stop`,
  favoriteRemove = 'favorite_remove',
  favoriteAdd = 'favorite_add',
  sessionStart = 'session_start',
  sessionEnd = 'session_end',
  search = 'search',
  pageOpen = 'page_open',
  epgView = 'epg_view',
  epgCategory = 'epg_category',
  epgChannel = 'epg_channel',
  epgProgram = 'epg_program',
}

export interface BEAnalyticsInfoOVP {
  // data from b.e.
  networkIp: string
  location: string
  contentUserSubscriberId: string // userGuid, get from BE
  contentUserAccountNumber: string
  contentUserProfileId: string
  preferredLanguage: string
}
// for player open
export interface IContentInfoOVP {
  contentId?: string
  contentType?: string
  contentUrl?: string
  contentTitle?: string
  contentOpenFrom?: string
  creatorId?: string
  contentErrorCode?: string
  playerSessionId?: string
}

export interface IEpgInfoOVP {
  channelId?: string
  channelTitle?: string
  tabId?: string
}

export interface IEpgCategoryInfoOVP {
  categoryId: string
  categoryTitle: string
}

export interface IEpgChannelInfoOVP {
  channelId: string
  channelTitle: string
  channelOrderPosition: string
  categoryId: string
  categoryTitle: string
}

export interface IEpgProgramInfoOVP extends IEpgChannelInfoOVP {
  programTitle: string
  programStart: string
  programEnd: string
  programStatus: string
}

export interface AnalyticsPlayerOpenInfoOVP
  extends ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    IContentInfoOVP {}

export interface AnalyticsEpgViewInfoOVP
  extends ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    WindowSessionGuidType,
    IEpgInfoOVP {}

export interface AnalyticsEpgCategoryInfoOVP
  extends ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    WindowSessionGuidType,
    IEpgCategoryInfoOVP {}

export interface AnalyticsEpgChannelInfoOVP
  extends ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    WindowSessionGuidType,
    IEpgChannelInfoOVP {}

export interface AnalyticsEpgProgramInfoOVP
  extends ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    WindowSessionGuidType,
    IEpgProgramInfoOVP {}

export interface ISearchInfoOVP {
  searchInput?: string
  contentSearchType?: string
}
export interface AnalyticsSearchInfoOVP
  extends ApplicationInfoOVP,
    BEAnalyticsInfoOVP,
    ISearchInfoOVP {}

export enum ContentType {
  movie = 'movie',
  series = 'series',
  live = 'live',
  // TODO: ? (available values: movie, series, live)
  epg = 'epg',
}
