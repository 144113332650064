import { COLOR } from '@setplex/arcane'
import { JSX, memo, type MemoExoticComponent } from 'react'
import LoadingBar from 'react-top-loading-bar'
import { LoaderGate, setLoaderRef } from './model'

// exports memoized component
export const Loader: MemoExoticComponent<typeof _Loader> = memo(_Loader)

// TODO: get rid of 'react-top-loading-bar', looks like it is abandoned
// or make fork of it
// check issues page https://github.com/klendi/react-top-loading-bar/issues
// and PRs page https://github.com/klendi/react-top-loading-bar/pulls

const loaderStyle = {
  backgroundColor: `var(${COLOR.PRIMARY})`,
}

_Loader.displayName = 'Loader'
function _Loader(): JSX.Element {
  return (
    <>
      <LoadingBar
        style={loaderStyle}
        shadow={false}
        height={4}
        ref={setLoaderRef}
      />
      <LoaderGate />
    </>
  )
}
