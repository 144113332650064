import { CategoryId, CategoryTitle } from '$/epg/categories'
import { CategoryIdHumanized } from '@/constants'
import type { IEpgCategoryInfoOVP } from '@setplex/tria-api'
import { combine, createEvent } from 'effector'
import { epgCategoriesWidgetModel } from './categories'

const getCategoryTitle = (id: number, title = '') => {
  switch (id) {
    case CategoryId.Favorite:
      return CategoryTitle.Favorite
    case CategoryId.All:
      return CategoryTitle.All
    default:
      return title
  }
}

// for 'epg_category' event
export const sendEpgCategoryEventData = createEvent<IEpgCategoryInfoOVP>()

// for 'epg_channel' event
export const sendEpgChannelEventData = createEvent<{
  channelId: string
  channelTitle: string
  channelOrderPosition: string
}>()

// for 'epg_program' event
export const sendEpgProgramEventData = createEvent<{
  channelId: string
  channelTitle: string
  channelOrderPosition: string
  programTitle: string
  programStart: string
  programEnd: string
  programStatus: string
}>()

export const $activeEpgCategory = combine(
  epgCategoriesWidgetModel.$selected,
  epgCategoriesWidgetModel.$categories,
  (selectedId, categories) => {
    if (selectedId === null) return null

    const category = categories.find((category) => category.id === selectedId)

    return {
      categoryId:
        selectedId === 0 ? CategoryIdHumanized.All : String(selectedId),
      categoryTitle: getCategoryTitle(selectedId, category?.title),
    }
  }
)
