import {
  AuthLayoutTemplate,
  BrandContainerDefault,
  type IAuthLayoutTemplateProps,
} from '@setplex/arcane'
import { JSX } from 'react'
import { PATH } from '~/shared/constants/path'

AuthLayout.displayName = 'AuthLayout'
export function AuthLayout({
  className,
  children,
}: IAuthLayoutTemplateProps): JSX.Element {
  return (
    <AuthLayoutTemplate className={className} posterUrl='/img/bg.jpg'>
      <a href={PATH.HOME}>
        <BrandContainerDefault logoUrl='/img/uvo-logo.svg' alt='UVOtv logo' />
      </a>
      {children}
    </AuthLayoutTemplate>
  )
}
