import { model as router } from '!/router'
import { lookbehind } from '@/effector'
import { F, T, aye } from '@/helpers'
import { email, maxLength, password, required } from '@/validators'
import { api } from '@setplex/tria-api'
import {
  attach,
  createEvent,
  createStore,
  sample,
  type EventCallable,
  type StoreWritable,
} from 'effector'
import { createForm } from 'effector-forms'
import { condition } from 'patronum'
import { model as session } from '~/entities/session'
import { $route } from '~/processes/navigation'
import { PATH } from '~/shared/constants/path'
import * as signUpModel from '~/widgets/auth/sign-up/model'
import { welcome } from '~/widgets/auth/welcome'

// init page
export const init: EventCallable<{ query: URLSearchParams }> = createEvent()
export const left = createEvent()

const $qrToken = createStore('').reset(left)

sample({
  clock: init,
  filter: ({ query }) => query.has('qrAuthToken'),
  fn: ({ query }) => query.get('qrAuthToken'),
  target: $qrToken,
})

// sign in effect
export const signInFx = attach({ effect: api.user.signInFx })

export const waitFx = session.checkStatusFx

// sign in form
export const signInForm = createForm({
  fields: {
    email: {
      init: '' as string,
      rules: [required(), email()],
    },
    password: {
      init: '' as string,
      rules: [required(), password(), maxLength(64)],
    },
    rememberMe: {
      init: false as boolean,
    },
  },
  validateOn: ['submit'],
})

// send sign in request
sample({
  clock: signInForm.formValidated,
  fn: ({ email, password, rememberMe }) => ({
    email: email.trim(),
    password,
    rememberMe,
  }),
  target: signInFx,
})

export const resetErrorOnClick: EventCallable<void> = createEvent()

const resetError = lookbehind({
  source: resetErrorOnClick,
  clear: init,
  within: 1000,
})

// error to show to the user
export const $error: StoreWritable<string | null> = createStore<string | null>(
  null
)
  .on(signInFx.failData, () => 'Incorrect email or Password')
  .reset(init, resetError)

sample({
  clock: init,
  filter: ({ query }) => query.has('error'),
  fn: ({ query }) => query.get('error') ?? null,
  target: $error,
})

// If first login by password - open welcome popup
sample({
  clock: signInFx.done,
  filter: session.$isFirstLogin,
  target: welcome.openWelcomePopup,
})

// after being authorized and triyng to sign in via QR code redirect to home with the same qrAuthToken
sample({
  clock: signInFx.done,
  source: $qrToken,
  filter: aye,
  fn: (qrToken: string) => `${PATH.HOME}?qrAuthToken=${qrToken}`,
  target: router.navigatePush,
})

sample({
  clock: signInFx.done,
  target: [api.userSession.checkStatusFx, api.userSession.getInfoFx],
})

export const tryToOpenSignInPopup: EventCallable<void> = createEvent()
export const openSignInPopup: EventCallable<void> = createEvent()

export const closeSignInPopup: EventCallable<void> = createEvent()
export const checkEnableToClose: EventCallable<void> = createEvent()

// Open Sign in popup only if user not authenticated
sample({
  clock: tryToOpenSignInPopup,
  filter: session.$isNotAuthenticated,
  target: openSignInPopup,
})

condition({
  source: checkEnableToClose,
  if: $error,
  then: resetError,
  else: closeSignInPopup,
})

sample({
  clock: [init, openSignInPopup],
  target: signInForm.reset,
})

const $isSignInPage = router.$location.map(
  (location) => location?.pathname.includes(PATH.SIGN_IN) ?? false
)

sample({
  clock: [init, session.$isAuthenticated],
  source: {
    isAuthenticated: session.$isAuthenticated,
    isSignInPage: $isSignInPage,
  },
  filter: ({ isAuthenticated, isSignInPage }) =>
    Boolean(isAuthenticated && isSignInPage),
  fn: () => PATH.HOME,
  target: router.navigatePush,
})

export const $isOpenedSignInPopup: StoreWritable<boolean> =
  createStore<boolean>(false)
    .on(openSignInPopup, T)
    .on([closeSignInPopup, session.$isAuthenticated], F)
    // close signIn popup if signUp popup opened
    .on(signUpModel.$isOpenedSignUpPopup, (state, value) =>
      value ? false : state
    )

// Redirect to Home when non-authenticated user opens My List directly (sign in popup will be shown), then closes popup
sample({
  clock: closeSignInPopup,
  source: {
    isNotAuthenticated: session.$isNotAuthenticated,
    route: $route,
  },
  filter: ({ isNotAuthenticated, route }) => {
    const routeName = route?.routeName
    return routeName === PATH.FAVORITES && isNotAuthenticated
  },
  fn: () => PATH.HOME,
  target: router.navigatePush,
})

// close signUp popup if signIn popup opened
sample({
  clock: $isOpenedSignInPopup,
  filter: $isOpenedSignInPopup,
  fn: F,
  target: signUpModel.$isOpenedSignUpPopup,
})
