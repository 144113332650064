import {
  format,
  formatDuration,
  type Duration,
  type DurationUnit,
} from 'date-fns'
import {
  ONE_DAY_MS,
  ONE_HOUR_MS,
  ONE_MINUTE_MS,
  ONE_SECOND_MS,
} from '../constants'
import { formatTimeString } from './strings'

export enum DATE_FORMATS {
  // 12:00 AM/PM localized
  HH_MM_LOCALIZED = 'p',
  // 12:00 localized
  hh_mm = 'hh:mm',
  // AM/PM localized
  AM_PM = 'a',
  // 25 Jan
  DD_MMM = 'dd MMM',
  // 2024
  YYYY = 'yyyy',
  // 2024-01-26
  YYYY_MM_DD = 'yyyy-MM-dd',
  // 11-29-17
  HH_MM_SS = 'HH-mm-ss',
  // Apr 29, 1453, 12:00 AM/PM localized
  MMM_DD_YYYY_HH_MM_LOCALIZED = 'PPp',
  // 24 Jan 12:00 AM/PM localized
  DD_MMM_HH_MM_LOCALIZED = 'dd MMM p',
  // Apr 29, 1453 localized
  MMM_DD_YYYY_LOCALIZED = 'PP',
  // Monday, 24 Jan
  EEEE_DD_MM = 'EEEE, dd MMM',
}

interface FormatDateDurationParams {
  duration: Duration
  format?: DurationUnit[]
  short?: boolean
}

// '2 years 9 months 1 week 7 days 5 hours 9 minutes 30 seconds'
// short: '2y 9m 1w 7d 5h 9m 30s'
export const formatDateDuration = ({
  duration,
  format,
  short,
}: FormatDateDurationParams) => {
  const durationString = formatDuration(duration, { format })
  const shortDurationString = formatTimeString(durationString)

  return short ? shortDurationString : durationString
}

/**
 * Convert date object to string like '19 August 2022'
 * @param date
 */
export const formatDate = (date: Date): string => {
  if (!date) return ''

  const day = date.getDate()
  const month = date.toLocaleString('en-GB', { month: 'long' })
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}

/**
 * Convert date object to string like 'Aug 19'
 * @param date
 */
export const formatDateShort = (date: Date): string => {
  if (!date) return ''

  const day = date.getDate()
  const month = date.toLocaleString('en-GB', { month: 'short' })

  return `${month} ${day}`
}

/**
 * Convert date object to string like '1900-02-27'
 * @param date
 */
export const formatDateToString = (date: Date): string => {
  if (!date) return ''

  return [
    String(date.getFullYear()),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0'),
  ].join('-')
}

/**
 * Convert date object in the format hh-mm-ss to string like '18-25-44'
 * @param date
 */
export const formatTimeToString = (date: Date): string => {
  if (!date) return ''

  return [
    String(date.getHours()).padStart(2, '0'),
    String(date.getMinutes()).padStart(2, '0'),
    String(date.getSeconds()).padStart(2, '0'),
  ].join('-')
}

/**
 * Convert date object to string like '19/02/2014'
 * @param date
 */
export const formatDateByPattern = (date: Date): string => {
  if (!date) return ''

  return date.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

/**
 * Convert string like '19/02/2014' to Date object
 * @param string
 */
export const formatStringToDate = (string: string): Date | null => {
  if (!string) return null

  return new Date(string)
}

/**
 * Return only year from date object as string like '2022'
 * @param date
 */
export const getYear = (date: Date): string => {
  if (!date) return ''

  const year = date.getFullYear()

  return `${year}`
}

/**
 * Return minutes between two dates
 * @param date
 */
export const getMinutesDifference = (date1: number, date2: number): number => {
  if (!date1 || !date2) return 0

  const minutes = Math.abs(date1 - date2) / 1000 / 60
  return minutes
}

/**
 * Set 05:00 - 06:00 PM (AM) - if PM (AM) only
 * Set 05:00 AM - 06:00 PM
 * @param startMs
 * @param stopMs
 */
export const formatDateToLabelInfo = (
  startMs: number | Date,
  stopMs: number | Date
): string => {
  const startNotation = format(startMs, DATE_FORMATS.AM_PM)
  const stopNotation = format(stopMs, DATE_FORMATS.AM_PM)
  const startDate = format(startMs, DATE_FORMATS.hh_mm)
  const endDate = format(stopMs, DATE_FORMATS.hh_mm)

  let result = `${startDate} ${startNotation} - ${endDate} ${stopNotation}`
  if (startNotation === stopNotation) {
    result = `${startDate} - ${endDate} ${stopNotation}`
  }

  return result
}

export const formatDateToLabel = (
  startMs: number | Date,
  stopMs: number | Date
): string => {
  const startDate = format(startMs, DATE_FORMATS.HH_MM_LOCALIZED)
  const endDate = format(stopMs, DATE_FORMATS.HH_MM_LOCALIZED)
  return `${startDate} - ${endDate}`
}

/**
 * Calculate time units within two dates or timestamps, or single time range
 * used for formatting time left
 */
export const timeWithin = (from: Date | number, to?: Date | number) => {
  const diff = Math.abs(to == null ? Number(from) : Number(to) - Number(from))
  const days = Math.floor(diff / ONE_DAY_MS)
  const hours = Math.floor((diff % ONE_DAY_MS) / ONE_HOUR_MS)
  const minutes = Math.floor((diff % ONE_HOUR_MS) / ONE_MINUTE_MS)
  const seconds = Math.floor((diff % ONE_MINUTE_MS) / ONE_SECOND_MS)
  return { days, hours, minutes, seconds }
}

export const asDate = (date: number | string | Date): Date => {
  return typeof date === 'number' || typeof date === 'string'
    ? new Date(date)
    : date
}

export const formatStartTime = (
  time: string | Date | number,
  formatTemplate: string
): string => {
  if (!time) return ''
  return format(asDate(time), formatTemplate)
}

/**
 * Set seconds to 0 in date or timestamp (`now` by default)
 */
export const zeroizeSeconds = (from: Date | number = Date.now()): number =>
  Math.trunc(Number(from) / ONE_MINUTE_MS) * ONE_MINUTE_MS

/**
 * Formats a timestamp into a complete date and time string with an optional time zone designator.
 *
 * @param {number} time - The timestamp in milliseconds (e.g., 1737383400000).
 * @param {boolean} [includeTimezone=true] - Whether to include the time zone designator (TZD) in the output.
 * If `true`, the time zone offset (e.g., "+03:00") is included. If `false`, "Z" (UTC) is appended instead.
 * @returns {string} - The formatted date and time string in the format `YYYY-MM-DDThh:mm:ssTZD`.
 * Example: "2025-01-20T12:30:00+03:00" or "2025-01-20T12:30:00Z".
 */
export const formatTimestampWithTimezoneOffset = (
  time: number,
  includeTimezone: boolean = true
): string => {
  const date = new Date(time)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  let formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

  if (includeTimezone) {
    const timezoneOffset = date.getTimezoneOffset()
    const offsetHours = Math.abs(Math.floor(timezoneOffset / 60))
      .toString()
      .padStart(2, '0')
    const offsetMinutes = Math.abs(timezoneOffset % 60)
      .toString()
      .padStart(2, '0')
    const timezoneSign = timezoneOffset > 0 ? '-' : '+'

    formattedTime += `${timezoneSign}${offsetHours}:${offsetMinutes}`
  } else {
    formattedTime += 'Z' // If time zone is not required, add Z (UTC)
  }

  return formattedTime
}
