export const PATH = {
  'DEFAULT': '',
  'HOME': '/',
  'ERROR': '/error',
  'GENRES_MOVIES': '/movies',
  'SEARCH': '/search',
  'RECOMMENDED': '/recommended',
  'FAVORITES': '/favorites',
  'CATEGORY': '/category',
  'LANGUAGE': '/language',
  'LANGUAGES': '/languages',
  'LIVE': '/live',
  'CATEGORIES': '/categories',
  'CHANNELS': '/channels',
  'MOVIES': '/movies',
  'HASHTAG': '/hashtag',
  'MOVIE': '/movie',
  'PLAY': '/play',
  'SETTINGS': '/settings',
  'TERMS_OF_USE': '/terms-of-use',
  'INDUSTRY': '/industry',
  'CONFIRM': '/confirm',
  'VERIFY': '/verify',
  'ABOUT': '/about',
  'GEOBLOCK': '/geoblock',
  'FAQ': '/faq',
  'NOT_FOUND_404': '(.*)',
  '404': '/404',

  // creators
  'CREATOR': '/creator',
  'CREATORS': '/creators',

  // static
  'STATIC': '/static',
  'STATIC_PRIVACY': '/static/privacy',
  'STATIC_SUPPORT': '/static/support',
  'SUPPORT': '/support',

  //privacy
  'PRIVACY': '/privacy',
  'PRIVACY_SENSITIVE': '/privacy/sensitive',
  'PRIVACY_YOUR_PRIVACY_CHOICES': '/privacy/your-privacy-choices',
  'PRIVACY_MYDATA': '/privacy/mydata',
  'SENSITIVE': '/sensitive',
  'YOUR_PRIVACY_CHOICES': '/your-privacy-choices',
  'MY_DATA': '/mydata',

  // auth
  'FORGOT_PASSWORD': '/forgot-password',
  'SIGN_IN': '/sign-in',
  'SIGN_UP': '/sign-up',
  'SIGN_UP_CONFIRM': '/sign-up/confirm',
  'RESET': '/reset',
}
