import { v4 as uuidv4 } from 'uuid'
import { toISOStringWithTimezone } from '../../utils/time'

/**
 * Escape value to be safe for SQL request
 * https://stackoverflow.com/questions/7744912/making-a-javascript-string-sql-friendly
 */
const escape = (v: string) =>
  String(v).replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, (char) => {
    // prettier-ignore
    switch (char) {
      case '\0': return '\\0'
      case '\x08': return '\\b'
      case '\x09': return '\\t'
      case '\x1a': return '\\z'
      case '\n': return '\\n'
      case '\r': return '\\r'
      case '"':
      case "'":
      case '\\':
      case '%': return '\\' + char // prepends a backslash to backslash, percent, and double/single quotes
      default: return char
    }
  })

/**
 * Escape value for SQL request, and wrap it in quotes
 */
const value = (v: string | undefined) =>
  "'" + (v !== undefined ? escape(v) : '') + "'"

/**
 * Format ClickHouse SQL request
 * https://docs.google.com/spreadsheets/d/1kGyfISEZEO2m5IjdKKIFHYjVQVjyWBVMTrp4w7RjoRw/edit#gid=350646525
 */
export const request = ({
  // common fields
  event_id = uuidv4(),
  event_timestamp = toISOStringWithTimezone(new Date()),
  config_accountCode = 'v.2.4', // Analytics doc version
  app_releaseVersion,
  app_name,
  app_id,
  device_OsVersion,
  device_model,
  device_name,
  device_type = 'web',
  device_id = '',
  device_mac_address = '',
  device_serial_number = '',
  network_ip,
  network_isp = '',
  network_type = '',
  network_location,
  content_provider_id = '',
  content_user_subscriber_id,
  content_user_account_number = '',
  content_user_profile_id = '',
  content_user_profile_name = '',

  // event fields
  event_type,
  event_parameter1,
  event_parameter2,
  event_parameter3,
  event_parameter4,
  event_parameter5,
  event_parameter6,
  event_parameter7,
  event_parameter8,
  event_parameter9,
  event_parameter10,
  event_parameter11,
  event_parameter12,
  event_parameter13,
  event_parameter14,
  event_parameter15,
  event_parameter16,
  event_parameter17,
  event_parameter18,
  event_parameter19,
  event_parameter20,
}: {
  event_id: string
  event_timestamp?: string
  config_accountCode: string
  app_releaseVersion: string
  app_name: string
  app_id: string
  device_OsVersion: string
  device_model: string
  device_name: string
  device_type?: string
  device_id?: string // user-agent
  device_mac_address?: string // not available for browser
  device_serial_number?: string // not available for browser
  network_ip: string
  network_isp?: string // not available, send ''
  network_type?: string // TODO: https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API ?
  network_location: string
  content_provider_id?: string //'uvo' or 'bot'
  content_user_subscriber_id: string // userGuid, get from BE
  content_user_account_number?: string // not available, send ''
  content_user_profile_id?: string // not available, send ''
  content_user_profile_name: string // window session guid, generated in the app

  event_type: string
  event_parameter1?: string
  event_parameter2?: string
  event_parameter3?: string
  event_parameter4?: string
  event_parameter5?: string
  event_parameter6?: string
  event_parameter7?: string
  event_parameter8?: string
  event_parameter9?: string
  event_parameter10?: string
  event_parameter11?: string
  event_parameter12?: string
  event_parameter13?: string
  event_parameter14?: string
  event_parameter15?: string
  event_parameter16?: string
  event_parameter17?: string
  event_parameter18?: string
  event_parameter19?: string
  event_parameter20?: string
}) =>
  // prettier-ignore
  `UVOtv Values (${[
    event_id,
    event_timestamp,
    config_accountCode,
    app_releaseVersion,
    app_name,
    app_id,
    device_OsVersion,
    device_model,
    device_name,
    device_type,
    device_id,
    device_mac_address,
    device_serial_number,
    network_ip,
    network_isp,
    network_type,
    network_location,
    content_provider_id,
    content_user_subscriber_id,
    content_user_account_number,
    content_user_profile_id,
    content_user_profile_name,
    event_type,
    event_parameter1,
    event_parameter2,
    event_parameter3,
    event_parameter4,
    event_parameter5,
    event_parameter6,
    event_parameter7,
    event_parameter8,
    event_parameter9,
    event_parameter10,
    event_parameter11,
    event_parameter12,
    event_parameter13,
    event_parameter14,
    event_parameter15,
    event_parameter16,
    event_parameter17,
    event_parameter18,
    event_parameter19,
    event_parameter20,
  ].map(value).join(',')})`

/**
 * Post analytics request to ClickHouse
 */
export const postAnalytics = async (domain: string | null, body: string) => {
  const params: URLSearchParams = new URLSearchParams()

  // https://docs.google.com/document/d/1B4RLt2U32Rav9-nzGcy2z-BtSUp753XBl-AZPXJTK-4/edit?usp=sharing
  params.set('user', 'devices')
  params.set('password', 'Mn94PCq35ieg0uYOGIpFoSoGrCoRQDYLkJneMdcUcz')
  params.set('max_result_rows', '1000')
  params.set('max_result_bytes', '10000000')
  params.set('result_overflow_mode', 'break')

  domain += domain ? (domain.endsWith('/') ? '' : '/') : ''
  return await fetch(`${domain}?${params}`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      ContentType: 'text/plain',
    },
    body,
  })
}
