import {
  epgCategoriesModel,
  LOAD_MORE_CATEGORIES_LIMIT,
} from '$/epg/categories'
import { createEvent, sample } from 'effector'

export const load = createEvent()

// load more categories
// it takes curretly loaded categories count, calculates offset number to load and loads next page
sample({
  clock: load,
  source: {
    loaded: epgCategoriesModel.$loaded,
    hasMore: epgCategoriesModel.$hasMore,
    pending: epgCategoriesModel.loadPageFx.pending,
  },
  filter: ({ hasMore, pending }) => hasMore && !pending,
  fn: ({ loaded }) => {
    const params = {
      offset: loaded.length,
      limit: LOAD_MORE_CATEGORIES_LIMIT,
      withChannelIds: 0,
    }
    return params
  },
  target: epgCategoriesModel.loadPageFx,
})
