import { type LiveChannelCategory } from '@setplex/tria-api'
import { CategoryId, CategoryType, type TvCategoryEx } from './index.h'

export const isType = (type: CategoryType) => (category: TvCategoryEx) =>
  category.type === type

export const isNotType = (type: CategoryType) => (category: TvCategoryEx) =>
  category.type !== type

export const toType =
  (type: CategoryType) =>
  (category: LiveChannelCategory): TvCategoryEx => ({
    ...category,
    type,
  })

export const toFavoriteType = toType(CategoryType.Favorite)
export const toAllType = toType(CategoryType.All)
export const toRegularType = toType(CategoryType.Regular)

export const idByType = (type: CategoryType) =>
  CategoryId[CategoryType[type] as keyof typeof CategoryType]
