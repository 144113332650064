import { A, pipe } from '@mobily/ts-belt'
import { api } from '@setplex/tria-api'
import { attach, createEvent, createStore, sample } from 'effector'
import { not } from 'patronum'
import { concatById, sortBySortOrder } from '~/shared/helpers'
import {
  CategoryId,
  CategoryTitle,
  CategoryType,
  type TvCategoryEx,
} from './index.h'
import {
  idByType,
  isNotType,
  toAllType,
  toFavoriteType,
  toRegularType,
} from './lib'

const favoriteCategory = {
  id: CategoryId.Favorite,
  sortOrder: CategoryType.Favorite,
  title: CategoryTitle.Favorite,
  type: CategoryType.Favorite,
}

// all loaded categories
export const $loaded = createStore<TvCategoryEx[]>([])

// flags that indicates if there are more categories to load
export const $isLast = createStore(false)
export const $hasMore = not($isLast)

// made-up categories (like "All", "Favorites")
export const $madeup = createStore<TvCategoryEx[]>([favoriteCategory])

// add/replace made-up category
export const addMadeupCategory = createEvent<TvCategoryEx>()

// add "Favorite" made-up category
export const addFavoriteCategory = addMadeupCategory.prepend<number[]>(
  (channelIds) => toFavoriteType({ channelIds })
)

// add "All" made-up category
export const addAllCategory = addMadeupCategory.prepend<number[]>(
  (channelIds) => toAllType({ channelIds })
)

// remove made-up category
export const removeMadeupCategory = createEvent<number>()

// remove "Favorite" made-up category
export const removeFavoriteCategory = removeMadeupCategory.prepend(
  () => CategoryId.Favorite
)

// load categories by page
export const loadPageFx = attach({
  effect: api.live.base.getLiveChannelsCategoriesFx,
})

// when page is loaded - concat new categories to the already loaded categories
sample({
  clock: loadPageFx.doneData,
  source: $loaded,
  fn: (loaded, content) =>
    pipe(
      content, //
      A.map(toRegularType),
      concatById(loaded),
      sortBySortOrder
    ),
  target: $loaded,
})

// when page is loaded - check if there are more categories to load
sample({
  clock: loadPageFx.done,
  fn: (data) => {
    if (!data) return false
    const { params, result } = data

    return Number(params?.offset) + Number(params.limit) >= result?.total
  },
  target: $isLast,
})

// add any made-up category
sample({
  clock: addMadeupCategory,
  source: $madeup,
  fn: (madeup, { type }) => {
    const categories = pipe(
      madeup,
      A.filter(isNotType(type)),
      A.append({
        id: idByType(type),
        sortOrder: type,
        type,
      } as TvCategoryEx),
      sortBySortOrder
    )
    return categories
  },
  target: $madeup,
})

// remove any made-up category
sample({
  clock: removeMadeupCategory,
  source: $madeup,
  fn: (madeup, toRemoveId) => A.reject(madeup, ({ id }) => id === toRemoveId),
  target: $madeup,
})
