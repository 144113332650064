import { CategoryType, isType, type TvCategoryEx } from '$/epg/categories'
import { A } from '@mobily/ts-belt'
import { type LiveChannel } from '@setplex/tria-api'

/**
 * Checks if made-up category should be added to the list of categories
 */
export const shouldAdd = (
  type: CategoryType,
  categories: TvCategoryEx[],
  got?: LiveChannel[]
) => {
  const hasCategory = A.filter(categories, isType(type)).length > 0
  if (hasCategory) return false // made-up category already added

  const hasChannels = got != null && A.isNotEmpty(got)
  return hasChannels
}
