import {
  CategoryId,
  CategoryType,
  epgCategoriesModel,
  LOAD_MORE_CATEGORIES_LIMIT,
} from '$/epg/categories'
import { A } from '@mobily/ts-belt'
import { api } from '@setplex/tria-api'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { and, combineEvents, not } from 'patronum'
import { id, T } from '~/shared/helpers'
import {
  INITIAL_CATEGORIES_LIMIT,
  INITIAL_CATEGORIES_OFFSET,
  INITIAL_CHANNELS_LIMIT,
  INITIAL_CHANNELS_OFFSET,
} from './index.h'
import { shouldAdd } from './lib'

// load initial categories list
export const load = createEvent<{ category?: number }>()

export const $favoritesReady = createStore(false)
export const $othersReady = createStore(false)
export const $ready = and($favoritesReady, $othersReady)

export const loadInitialCategories = createEvent<{ category?: number }>()
export const loadAllCategoryChannels = createEvent()
export const loadFavoritesCategoryChannels = createEvent()

// load initial categories list effect by query in url
const loadInitialCategoriesFx = createEffect<{ category?: number }, void>(
  async ({ category: id }) => {
    let offset = INITIAL_CATEGORIES_OFFSET
    let limit = INITIAL_CATEGORIES_LIMIT
    let loaded = 0

    for (;;) {
      const data = await epgCategoriesModel.loadPageFx({
        offset,
        limit,
        withChannelIds: 0,
      })
      // there is no initial selected category or it is made-up
      if (id == null || id <= 0) break

      // no more categories to load
      if (offset >= data.total || !data || data.length === 0) break

      // found initial category
      if (data.findIndex((c) => c.id === id) >= 0) break

      // otherwise continue loading
      loaded += data.length
      offset = loaded
      limit = LOAD_MORE_CATEGORIES_LIMIT
    }
  }
)

// load "All" made-up category channels effect
// * do not use `channelsEntity.loadPageFx` intentionally,
// * so response channels will not end up in the `channelsEntity.$byCategory` store
const loadAllCategoryChannelsFx = attach({
  mapParams: () => ({
    offset: INITIAL_CHANNELS_OFFSET,
    limit: INITIAL_CHANNELS_LIMIT,
    categoryId: CategoryId.All,
  }),
  effect: api.live.base.getLiveChannelsFx,
})

// load "Favorites" made-up category channels effect
// * do not use `channelsEntity.loadPageFx` intentionally,
// * so response channels will not end up in the `channelsEntity.$byCategory` store
const loadFavoritesCategoryChannelsFx = attach({
  mapParams: () => ({
    offset: INITIAL_CHANNELS_OFFSET,
    limit: INITIAL_CHANNELS_LIMIT,
  }),
  effect: api.live.base.getFavoritesFx,
})

// prevent double categories loading
sample({
  clock: loadInitialCategories,
  filter: not(loadInitialCategoriesFx.pending),
  target: loadInitialCategoriesFx,
})

// prevent double All category loading
sample({
  clock: loadAllCategoryChannels,
  filter: not(loadAllCategoryChannelsFx.pending),
  target: loadAllCategoryChannelsFx,
})

// prevent double Favorites category loading
sample({
  clock: loadFavoritesCategoryChannels,
  filter: not(loadFavoritesCategoryChannelsFx.pending),
  target: loadFavoritesCategoryChannelsFx,
})

// load initial categories list
sample({
  clock: load,
  filter: not($othersReady),
  target: [
    loadInitialCategories,
    loadFavoritesCategoryChannels,
    loadAllCategoryChannels,
  ],
})

// set ready flag when all categories are loaded and determined
sample({
  clock: combineEvents({
    events: [
      loadInitialCategoriesFx.finally,
      loadAllCategoryChannelsFx.finally,
    ],
  }),
  fn: T,
  target: $othersReady,
})

// set ready flag for favorites channels
sample({
  clock: loadFavoritesCategoryChannelsFx.finally,
  fn: T,
  target: $favoritesReady,
})

// add "All" made-up category, if there are channels in it
sample({
  clock: loadAllCategoryChannelsFx.doneData,
  source: epgCategoriesModel.$madeup,
  filter: (madeup, content) => shouldAdd(CategoryType.All, madeup, content),
  fn: (_, content) => A.map(content ?? [], id),
  target: epgCategoriesModel.addAllCategory,
})

// add "Favorites" made-up category, if there are channels in it
sample({
  clock: loadFavoritesCategoryChannelsFx.doneData,
  source: epgCategoriesModel.$madeup,
  filter: (madeup, content) =>
    shouldAdd(CategoryType.Favorite, madeup, content),
  fn: (_, content) => A.map(content ?? [], id),
  target: epgCategoriesModel.addFavoriteCategory,
})
